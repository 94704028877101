import React from 'react'
import HubspotForm from 'react-hubspot-form'

const PopUp = ({ condition, closeModal, idForm }) => {
  return (
    <div className={condition ? `w-11/12 xl:w-7/12 2xl:w-4/12 rounded-xl flex justify-center bg-white items-center fixed PopUp` : `px-10 py-5 w-9/12 rounded-xl flex justify-center bg-white items-center fixed closePop`} style={{ boxShadow: '1px 1px 20px #686869' }}>
            <div className='flex flex-col justify-center items-center w-full'>
                <div className='flex justify-end w-full px-10 py-5'><p onClick={closeModal} className='cursor-pointer text-3xl font-bold p-0 m-0 text-gray-500'>X</p></div>
                <div id="register" className="card-1 hubspot-btn w-full pb-10 rounded-xl text-gray-900">
                    <h2 class = "mt-16 md:mt-0 md:mb-0 mb-2 font-bold text-2xl text-gray-800 text-center">Get the Webcast Right in your Inbox!</h2>
                    <p class = "text-sm mb-8 text-center mt-2">Fill out the form below</p>
                            <div class = {idForm === '0' ? "flex justify-center items-center" : "hidden"}>
                                <HubspotForm
                                    portalId='6153052'
                                    formId='8bde6623-cf08-4565-ad59-c30c0264e29d'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                            </div>  
                            
                            <div class = {idForm === '1' ? "flex justify-center items-center" : "hidden"}>
                              <HubspotForm
                                    portalId='6153052'
                                    formId='bbc6805a-432e-4097-97a4-ec9f76a5006a'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                            </div>
                            <div class = {idForm === '2' ? "flex justify-center items-center" : "hidden"}>
                                 <HubspotForm
                                    portalId='6153052'
                                    formId='9dbaddc3-13dd-4b68-ae4d-7fc8c2fb9b5b'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                            </div>
                            <div class = {idForm === '3' ? "flex justify-center items-center" : "hidden"}>
                                <HubspotForm
                                    portalId='6153052'
                                    formId='f8dee905-5551-444b-8e57-2dddfb69a116'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                            </div>
                            <div class = {idForm === '4' ? "flex justify-center items-center" : "hidden"}>
                                <HubspotForm
                                    portalId='6153052'
                                    formId='d0609c24-2586-41c6-a66d-cfaf11dab671'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                            </div>
                            <div class = {idForm === '5' ? "flex justify-center items-center" : "hidden"}>
                                <HubspotForm
                                    portalId='6153052'
                                    formId='22a49be2-3b5d-47b3-863c-5d1f344c0beb'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                            </div>
                            <div class = {idForm === '6' ? "flex justify-center items-center" : "hidden"}>
                                <HubspotForm
                                    portalId='6153052'
                                    formId='14530dc0-7bef-4423-8626-bbf260aa8a6c'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                            </div>
                    <p class = "text-sm text-center">We safeguard your <a href="/privacy-policy/" class = "text-purple-500">Privacy</a></p>
                    </div>
            </div>
    </div>
  )
}

export default PopUp