import React, { useState } from "react";
import ReactDOM from "react-dom";
// import { Trans } from 'gatsby-plugin-react-i18next';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel'
import Trans from '../../components/Trans'
import { Modal } from 'react-responsive-modal';
import HubspotForm from 'react-hubspot-form'
import PopUp from "../PopUp";

const WebcastCarousel = () => {
    const [open, setOpen] = useState(false);
    const [formID, setformID] = useState(``)
    const onOpenModal = (i) => {
        setOpen(true);
        setformID(i)
    }
    const onCloseModal = () => setOpen(false);
    
    return (
<div className = "testimonial-slider container text-center px-6 py-10 md:py-28">
<h2 className = "text-2xl md:text-3xl lg:text-5xl text-gray-250">Previous <span className = "relative">Webcasts<img className = "absolute -bottom-1 right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" /> </span></h2>
    <h2 className="section-title mb-14 md:mb-28">
        <Trans>Our Customers Love Us as Much as We Love Them.<br/> See for Yourself!</Trans>
    </h2>
    <CarouselProvider className="max-w-5xl relative mx-auto"
        naturalSlideWidth={100}
        naturalSlideHeight={65}
        totalSlides={5}
    >
        {/* <div className="quote-icon1 relative opacity-40 z-10">
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" />
        </div> */}
      
        <Slider className="rounded-lg shadow-xl z-0 w-full h-50">
            <Slide index={0} className="bg-gradient-to-br from-purple-100 to-pink-130 overflow-y-scroll lg:overflow-hidden"  style = {{background: 'rgb(248,248,255)',
            background: 'linearGradient(142deg, rgba(248,248,255,1) 29%, rgba(255,255,255,1) 91%)'}}>
                <div className="mx-auto py-9 px-3 flex flex-col justify-center items-center">
                   
                   <div className = "flex justify-start w-full px-2 mt-10 mb-6"><p className = "text-left text-gray-200 font-bold  text-lg md:text-xl lg:text-2xl">Episode 5: Organizational Culture: How Top Leaders Use Recognition To Shape Cultures And Impact The Bottom Line.</p></div>
                   <div className = "grid lg:grid-cols-2 gap-8 justify-between items-center px-2">
                     <div className = "w-full w-1/5 flex justify-center items-center">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/Vantage-Point-US-EP-5.jpeg" />
                     </div>
                    <div className = "w-full lg:w-4/5 flex flex-col items-center">
                        <div className = "flex w-11/12 lg:justify-start mb-4 xl:mb-0 2xl:mb-1"><p className = "text-center lg:text-left text-gray-200 text-lg lg:text-xl">Guest Speakers -</p></div>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 justify-center w-full md:w-5/6 lg:w-full xl:w-full pl-5 md:pl-0 lg:pl-0 xl:px-0 relative">
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/ep5-Jennifer.png" alt="Jennifer, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Jennifer McCollum</h2>
                                <p class="text-gray-200 text-sm text-left">CEO of Linkage</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/ep5-Jeanie.png" alt="Jeanie, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Jeanie Tung</h2>
                                <p class="text-gray-200 text-sm text-left">Director of BD at Henry Street Settlement</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/ep5-Angel-low.png" alt="Angel-low, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Angel Lowe</h2>
                                <p class="text-gray-200 text-sm text-left">VP, Global Human Resources at Atkore</p>
                            </div>
                        </div>
                        <div className = "w-full flex justify-center lg:justify-start mt-8 lg:mt-3"><div onClick = {() => onOpenModal(`4`)} className = "w-full md:w-1/2 lg:w-10/12 lg:w-5/6 xl:w-full cursor-pointer"><p className="vc-new-orange-btn">Get Webcast Recordings</p></div></div>
                    </div>
                   </div>
                </div>
            </Slide> 
            <Slide index={1} className="bg-gradient-to-br from-purple-100 to-pink-130 overflow-y-scroll lg:overflow-hidden"  style = {{background: 'rgb(248,248,255)',
            background: 'linearGradient(142deg, rgba(248,248,255,1) 29%, rgba(255,255,255,1) 91%)'}}>
                <div className="mx-auto py-9 px-3 flex flex-col justify-center items-center">
                   
                   <div className = "flex justify-start w-full px-2 mt-10 mb-6"><p className = "text-left text-gray-200 font-bold  text-lg md:text-xl lg:text-2xl">Episode 4 : Key To Continuous Growth: Driving Employee Engagement With Effective Performance Management.</p></div>
                   <div className = "grid lg:grid-cols-2 gap-8 justify-between items-center px-2">
                     <div className = "w-full w-1/5 flex justify-center items-center">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/vantage-point-US-Episode-4-post-webinar-Thumbnail.jpg" />
                     </div>
                    <div className = "w-full lg:w-4/5 flex flex-col items-center">
                        <div className = "flex w-11/12 lg:justify-start mb-4 xl:mb-0 2xl:mb-1"><p className = "text-center lg:text-left text-gray-200 text-lg lg:text-xl">Guest Speakers -</p></div>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 justify-center w-full md:w-5/6 lg:w-full xl:w-full pl-5 md:pl-0 lg:pl-0 xl:px-0 relative">
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/07/Stephane.png" alt="Stephane, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Stephane Charbonnier</h2>
                                <p class="text-gray-200 text-sm text-left">CHRO, L'Oreal, USA</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/07/Dorie-Clark.png" alt="Dorie-Clark, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Dorie Clark</h2>
                                <p class="text-gray-200 text-sm text-left">#1 Communication coach in the World, Thought Leader and Business Professor</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/07/Anne-Gotte.png" alt="Anne-Gotte, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Anne Gotte</h2>
                                <p class="text-gray-200 text-sm text-left">Vice President, Global Talent Management, Bumble</p>
                            </div>
                        </div>
                        <div className = "w-full flex justify-center lg:justify-start mt-8 lg:mt-3"><div onClick = {() => onOpenModal(`3`)} className = "w-full md:w-1/2 lg:w-10/12 lg:w-5/6 xl:w-full cursor-pointer"><p className="vc-new-orange-btn">Get Webcast Recordings</p></div></div>
                    </div>
                   </div>
                </div>
            </Slide>
            <Slide index={2} className="bg-gradient-to-br from-purple-100 to-pink-130 overflow-y-scroll lg:overflow-hidden"  style = {{background: 'rgb(248,248,255)',
            background: 'linearGradient(142deg, rgba(248,248,255,1) 29%, rgba(255,255,255,1) 91%)'}}>
                <div className="mx-auto py-9 px-3 flex flex-col justify-center items-center">
                   
                   <div className = "flex justify-start w-full px-2 mt-10 mb-6"><p className = "text-left text-gray-200 font-bold  text-lg md:text-xl lg:text-2xl">Episode 3 : Empathy Boosts Productivity: How do we build an Empathetic Culture?</p></div>
                   <div className = "grid lg:grid-cols-2 gap-8 justify-between items-center px-2">
                     <div className = "w-full w-1/5 flex justify-center items-center">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/Thumbnail-Episode-3.jpg" />
                     </div>
                    <div className = "w-full lg:w-4/5 flex flex-col items-center">
                        <div className = "flex w-11/12 lg:justify-start mb-4 xl:mb-0 2xl:mb-1"><p className = "text-center lg:text-left text-gray-200 text-lg lg:text-xl">Guest Speakers -</p></div>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 justify-center w-full md:w-5/6 lg:w-full xl:w-full pl-5 md:pl-0 lg:pl-0 xl:px-0 relative">
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/04/Andy-Holmes-Guest-Speaker-Vantage-Point-Webcasts.png" alt="Andy-Holmes, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Andy Holmes</h2>
                                <p class="text-gray-200 text-sm text-left">Former Global Head of Wellbeing Reckitt</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/06/Zeenat-Ladhani-1.png" alt="Zeenat Ladhani, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Zeenat Ladhani</h2>
                                <p class="text-gray-200 text-sm text-left">Head of HR Daiichi Sankyo</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/06/Susan-Winchester-1.png" alt="Susan Winchester, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Susan Winchester</h2>
                                <p class="text-gray-200 text-sm text-left">SVP, Chief Human Resources Officer Applied Materials</p>
                            </div>
                        </div>
                        <div className = "w-full flex justify-center lg:justify-start mt-8 lg:mt-3"><div onClick = {() => onOpenModal(`2`)} className = "w-full md:w-1/2 lg:w-10/12 lg:w-5/6 xl:w-full cursor-pointer"><p className="vc-new-orange-btn">Get Webcast Recordings</p></div></div>
                    </div>
                   </div>
                </div>
            </Slide>  
            <Slide index={3} className="bg-gradient-to-br from-purple-100 to-pink-130 overflow-y-scroll lg:overflow-hidden"  style = {{background: 'rgb(248,248,255)',
            background: 'linearGradient(142deg, rgba(248,248,255,1) 29%, rgba(255,255,255,1) 91%)'}}>
                <div className="mx-auto py-9 px-3 flex flex-col justify-center items-center">
                   
                   <div className = "flex justify-start w-full px-2 mt-10 mb-6"><p className = "text-left text-gray-200 font-bold  text-lg md:text-xl lg:text-2xl">Episode 2 : How focusing on Engagement, Empathy and Wellness are helping companies beat the Great Resignation</p></div>
                   <div className = "grid lg:grid-cols-2 gap-8 justify-between items-center px-2">
                     <div className = "w-full w-1/5 flex justify-center items-center">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/06/episode-2-vantage-point-webcast-thumbnail.jpg" />
                     </div>
                    <div className = "w-full lg:w-4/5 flex flex-col items-center">
                        <div className = "flex w-11/12 lg:justify-start mb-4 xl:mb-0 2xl:mb-1"><p className = "text-center lg:text-left text-gray-200 text-lg lg:text-xl">Guest Speakers -</p></div>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 justify-center w-full md:w-5/6 lg:w-full xl:w-full pl-5 md:pl-0 lg:pl-0 xl:px-0 relative">
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/04/Peter-Bregman-Guest-Speaker-Vantage-Point-Webcasts.png" alt="Nabeela Ixtabalan, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Peter Bregman</h2>
                                <p class="text-gray-200 text-sm text-left">CEO of Bregman Partners</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/04/Darcy-Verhun-Guest-Speaker-Vantage-Point-Webcasts.png" alt="Melissa Salibi, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Darcy Verhun</h2>
                                <p class="text-gray-200 text-sm text-left">President of FYidoctors</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/04/Maryjo-Charbonnier-Guest-Speaker-Vantage-Point-Webcasts.png" alt="Xavier Joly, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Maryjo Charbonnier</h2>
                                <p class="text-gray-200 text-sm text-left">CHRO of Kyndryl</p>
                            </div>
                        </div>
                        <div className = "w-full flex justify-center lg:justify-start mt-8 lg:mt-3"><div onClick = {() => onOpenModal(`1`)} className = "w-full md:w-1/2 lg:w-10/12 lg:w-5/6 xl:w-full cursor-pointer"><p className="vc-new-orange-btn">Get Webcast Recordings</p></div></div>
                    </div>
                   </div>
                </div>
            </Slide> 
            <Slide index={4} className="bg-gradient-to-br from-purple-100 to-pink-130 overflow-y-scroll lg:overflow-hidden"  style = {{background: 'rgb(248,248,255)',
            background: 'linearGradient(142deg, rgba(248,248,255,1) 29%, rgba(255,255,255,1) 91%)'}}>
                <div className="mx-auto py-9 px-3 flex flex-col justify-center items-center">
                   
                   <div className = "flex justify-start w-full px-2 mt-10 mb-6"><p className = "text-left text-gray-200 font-bold  text-lg md:text-xl lg:text-2xl">Episode 1: Employee Engagement in a Hybrid, Post-COVID World</p></div>
                   <div className = "grid lg:grid-cols-2 gap-8 justify-between items-center px-2">
                     <div className = "w-full w-1/5 flex justify-center items-center">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/05/Employee-Engagement-in-a-hybrid-post-covid-world-Vantage-Webcast-EP-01-Thumbnail.jpg" />
                     </div>
                    <div className = "w-full lg:w-4/5 flex flex-col items-center">
                        <div className = "flex w-11/12 lg:justify-start mb-4 xl:mb-0 2xl:mb-1"><p className = "text-center lg:text-left text-gray-200 text-lg lg:text-xl">Guest Speakers -</p></div>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 justify-center w-full md:w-5/6 lg:w-full xl:w-full pl-5 md:pl-0 lg:pl-0 xl:px-0 relative">
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/04/Guest1.png" alt="Nabeela Ixtabalan, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Nabeela Ixtabalan</h2>
                                <p class="text-gray-200 text-sm text-left">EVP, Walmart</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/04/Guest2.png" alt="Melissa Salibi, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Melissa Salibi</h2>
                                <p class="text-gray-200 text-sm text-left">CHRO, Kempinski Hotels</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/04/Guest-3.png" alt="Xavier Joly, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Xavier Joly</h2>
                                <p class="text-gray-200 text-sm text-left">VP, Bayer Crop Science</p>
                            </div>
                        </div>
                        <div className = "w-full flex justify-center lg:justify-start mt-8 lg:mt-3"><div onClick = {() => onOpenModal(`0`)} className = "w-full md:w-1/2 lg:w-10/12 lg:w-5/6 xl:w-full cursor-pointer"><p className="vc-new-orange-btn">Get Webcast Recordings</p></div></div>
                    </div>
                   </div>
                </div>
            </Slide>   
        </Slider>
        <ButtonBack className="shadow eb-carousel-btn p-2 rounded-full flex justify-center items-center">
            <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
        </ButtonBack>
        <ButtonNext className="shadow eb-carousel-btn p-2 rounded-full flex justify-center items-center">
            <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
        </ButtonNext>
    </CarouselProvider>


    <section>
        <div className={open ? 'absolute top-0 left-0 h-full w-full z-50 flex' : 'hidden'}>
            <div className='fixed bg-gray-500 opacity-50 h-full w-full'></div>
        </div>

        <div className={ open ? 'w-full h-full flex justify-center items-center absolute left-0 top-0 bg-gray z-50 PopUp' : ''}>
            <PopUp condition = {open} closeModal = {onCloseModal} idForm = {formID}/>
        </div>
    </section>

    {/* <Modal open={open} onClose={onCloseModal} center>
        <div className="form-section">
            <h2 className="form-title mt-2 mb-10 text-2xl font-semibold text-gray-900 sm:text-3xl pb-2">Let us know your details</h2>
            <HubspotForm
                portalId='6153052'
                formId='8bde6623-cf08-4565-ad59-c30c0264e29d'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
            />
            <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a></div>
        </div>
    </Modal> */}
</div>
    )
};


const renderWebcastCarousel=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#webcastcarousel")
        if (id != null){
            ReactDOM.render(
                <WebcastCarousel />,
                id
            )
        }
    }
}

setTimeout(renderWebcastCarousel,100)

export default WebcastCarousel;